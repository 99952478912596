<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            Feedback Content
            <v-spacer></v-spacer>
            <!-- <v-flex>
              <v-dialog
                :retain-focus="false"
                v-model="dialog"
                persistent
                max-width="800px"
                :key="dialog"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="green" v-bind="attrs" v-on="on">
                    Add
                  </v-btn>
                </template>
                <v-card>
                  <v-form v-model="addslider" ref="addcat">
                    <v-card-title>
                      <span class="headline">Add Feedback Content</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              outlined
                              v-model="title"
                              label=" Title"
                              :rules="Rules"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              outlined
                              v-model="toptext"
                              label=" Please enter the top content"
                              :rules="Rules"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              outlined
                              v-model="bottomtext"
                              label=" Please enter the bottom content"
                              :rules="Rules"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialogclose()">
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="!addslider"
                        @click="add()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-flex> -->
          </v-card-title>
          <div v-if="user">
            <v-layout wrap pb-10>
              <v-flex md3 pa-4 v-for="(item, i) in user" :key="i">
                <v-card style="line-height: 16px" tile flat>
                  <v-layout wrap justify-center px-2>
                    <v-flex xs12 pt-5>
                      <v-img :src="mediaURL + item.image"> </v-img>
                    </v-flex>
                    <v-flex py-5 xl12 sm12 md12 lg12 xs12>
                      <span style="font-size: 16px; color: #000000">
                        {{ item.title }} </span
                      ><br /><br />
                      <v-col
                        cols="12"
                        md="12"
                        style="font-size: 13px; color: #000000"
                      >
                        <span style="font-weight: bold"> Top Content:</span>
                        {{ item.toptext }}
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                        style="font-size: 13px; color: #000000"
                      >
                        <span style="font-weight: bold">Bottom Content:</span>
                        {{ item.bottomtext }}
                      </v-col>
                    </v-flex>
                    <v-layout wrap justify-center px-2>
                      <v-flex pt-5 xl6 lg6 md6 pb-2 sm6 xs6 px-5 justify-center>
                        <v-btn
                          outlined
                          small
                          block
                          @click="editSlider(item)"
                          color="green"
                        >
                          Edit
                        </v-btn>
                      </v-flex>
                      <br />
                    </v-layout>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
            <v-dialog v-model="editdialog" max-width="600px">
              <v-card>
                <v-card-title>
                  <span class="headline">Edit Feedback Content</span>
                </v-card-title>
                <v-card-text>
                  <v-layout py-5>
                    <v-flex xs12 md12 lg12>
                      <ImageComp
                        :singleImage="editingitem.image"
                        :pageId="editingitem._id"
                        @stepper="winStepper"
                        :height="320"
                        :width="1280"
                        :heading="'Upload  Image (1280 * 320)'"
                        :componentType="'popUpImage'"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12 md12 lg12>
                      <v-text-field
                        outlined
                        v-model="editingitem.title"
                        label=" Title"
                        :rules="Rules"
                        required
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12 md12 lg12>
                      <v-text-field
                        outlined
                        v-model="editingitem.toptext"
                        label=" Please enter the top content"
                        :rules="Rules"
                        required
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12 md12 lg12>
                      <v-text-field
                        outlined
                        v-model="editingitem.bottomtext"
                        label=" Please enter the bottom content"
                        :rules="Rules"
                        required
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="editdialog = false">
                    Close
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="edit()">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <div v-else>
            <v-layout pt-15 justify-center fill-height wrap>
              <v-flex text-center xs12 lg12>
                <span style="font-size: 22px; letter-spacing: 1px">
                  No Data Found...
                  <br />
                  Try adding Data.
                </span>
              </v-flex>
            </v-layout>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import ImageComp from "@/components/commonComponents/singleImage";
export default {
  components: {
    ImageComp,
  },
  data() {
    return {
      ServerError: false,
      widthOfCard: "250px",
      title: "",
      showsnackbar: false,
      msg: null,
      pages: 0,
      toptext: "",
      bottomtext: "",
      appLoading: false,
      dialogDelete: false,
      dialog: false,
      dialog1: false,
      editdialog: false,
      editingitem: [],
      popUpImage: null,
      user: [],
      data: [],
      addslider: false,
      rules: {
        required: (value) =>
          /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/.test(
            value
          ) || "Must be link",
        min: (v) => v.length >= 10 || "Min 10 characters",
      },
      Rules: [(value) => !!value || "Required."],
      titleRule: [(v) => v.length < 50 || "Min 50 characters"],
    };
  },

  mounted() {
    this.getData();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "popUpImage") {
        this.popUpImage = window_data.selectedFiles;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/home/getsubscribetext/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    // add() {
    //   var data = {};
    //   data["title"] = this.title;
    //   data["toptext"] = this.toptext;
    //   data["bottomtext"] = this.bottomtext;
    //   axios({
    //     url: "/home/setsubscribetext",
    //     method: "POST",
    //     data: data,
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;

    //       if (response.data.status) {
    //         this.getData();
    //         this.msg = "Added Sucessfully";
    //         this.showsnackbar = true;
    //         this.link = null;
    //         this.title = null;
    //         this.caption = null;
    //         this.date = null;
    //         this.imagePreview = null;
    //         this.file = null;
    //         this.dialog = false;
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showsnackbar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
    edit() {
      this.appLoading = true;
      var user = {};
      user["title"] = this.editingitem.title;
      user["toptext"] = this.editingitem.toptext;
      user["bottomtext"] = this.editingitem.bottomtext;
      user["id"] = this.editingitem._id;
      axios({
        method: "POST",
        url: "/home/setsubscribetext",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: user,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            if (this.popUpImage) {
              this.uploadImage(this.editingitem._id);
            } else {
              this.getData();
              this.msg = "Updated Sucessfully";
              this.editdialog = false;
            }
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadImage(id) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("photo", this.popUpImage);

      axios({
        method: "POST",
        url: "/home/subscribetext/upload/image",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.editdialog = false;
            this.dialogmain = false;
            this.exclusiveMainImage = null;
            this.exclusiveImage = null;
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.link = null;
      this.dialog = false;
    },
    editSlider(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
  },
};
</script>